import { useStore } from 'vuex'
import { computed } from 'vue'

export default (props) => {

  const store = useStore()

  // computed
  const filterForm = computed(() => {
    return store.state.forms[props.filterFormName.value]
  })

  const paginationList = computed(() => {
    return store.state.pagination[props.paginationListName.value]
  })

  const selectedFilterOptions = computed(() => {

    const filterOptionMap = {}

    Object.keys(filterForm.value.fields).forEach((fieldName) => {

      const field = filterForm.value.fields[fieldName]

      if (!Array.isArray(field.value)) return

      filterOptionMap[fieldName] = field.options.filter((option) => {
        return field.value.includes(option.value)
      })

    })

    return filterOptionMap

  })

  const timerangeFieldName = computed(() => {
    return Object.keys(filterForm.value.fields).find((fieldName) => {
      return filterForm.value.fields[fieldName].metaType === 'timerange'
    })
  })

  const selectedTimerangeOption = computed(() => {
    if (!timerangeFieldName.value) return null
    return filterForm.value.fields[timerangeFieldName.value].options.find((option) => {
      return filterForm.value.fields[timerangeFieldName.value].value === option.value
    })
  })

  const filterFieldNames = computed(() => {
    return Object.keys(selectedFilterOptions.value)
  })

  const numSelectedFilters = computed(() => {
    return filterFieldNames.value.reduce((accumulator, itemKey) => {
      return accumulator + selectedFilterOptions.value[itemKey].length
    }, 0)
  })

  // methods
  const removeSelectedFilterOption = (fieldName, option) => {
    const newValue = Array.from(filterForm.value.fields[fieldName].value)
      .filter((value) => {
        return value !== option.value
      })
    store.commit('forms/SET_FIELD_VALUE', {
      formName: props.filterFormName.value,
      fieldName,
      newValue,
    })
  }

  const setAllFieldsDisabled = (newValue) => {
    Object.keys(filterForm.value.fields).forEach((fieldName) => {
      store.commit('forms/SET_FIELD_DISABLED', {
        formName: props.filterFormName.value,
        fieldName,
        newValue,
      })
    })
  }

  const applyFilters = () => {
    return store.dispatch('pagination/APPLY_FILTER_FORM', {
      filterFormName: props.filterFormName.value,
      listName: props.paginationListName.value,
    })
  }

  const resetFilterForm = () => {
    store.commit('forms/RESET_FORM', props.filterFormName.value)
  }

  resetFilterForm()
  setAllFieldsDisabled(paginationList.value.isLoading)

  return {
    filterForm,
    paginationList,
    filterFieldNames,
    timerangeFieldName,
    numSelectedFilters,
    selectedFilterOptions,
    selectedTimerangeOption,

    applyFilters,
    resetFilterForm,
    setAllFieldsDisabled,
    removeSelectedFilterOption,
  }

}
