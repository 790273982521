<template>

  <!--
    @NOTE: this tooltip is currently disabled because J doesn't like how it's
    centered over the whole button group
  -->

  <!-- <template v-if="!shouldShowTooltip"> -->
    <FormRadio
      :showLabel="false"
      :showError="false"
      type="button-group"
      :formName="formName"
      :fieldName="fieldName"
    />
  <!-- </template>
  <template v-else>
    <Tooltip>
      <template #trigger>
        <FormRadio
          :showLabel="false"
          :showError="false"
          type="button-group"
          :formName="formName"
          :fieldName="fieldName"
        />
      </template>
      <p>
        {{ tooltipSentence }}
      </p>
      <p>
        <router-link to="/account/plans">View plans</router-link>
      </p>
    </Tooltip>
  </template> -->
</template>

<script>

  import { mapState } from 'vuex'

  import useFilters from '@/composables/useFilters'

  // import Tooltip from '@/components/utils/Tooltip.vue'
  import FormRadio from '@/components/forms/FormRadio.vue'

  export default {
    components: {
      // Tooltip,
      FormRadio,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
    },
    setup() {
      const { formatMaxEventsMonthly, formatMaxEventsTimeframe } = useFilters()
      return { formatMaxEventsMonthly, formatMaxEventsTimeframe }
    },
    computed: {
      ...mapState('user', ['userPlan']),
      ...mapState('app', ['stripePlansSlugMap']),
      shouldShowTooltip() {
        if (!this.userPlan) return true
        return this.$store.state.forms[this.formName].fields[this.fieldName].options.some((option) => {
          return option.disabled
        })
      },
      tooltipSentence() {
        if (!this.userPlan) return null
        switch (this.userPlan.slug) {
          case 'free': return `View ${this.formatMaxEventsTimeframe(this.stripePlansSlugMap.starter)} of history with ${this.stripePlansSlugMap.starter.name} or ${this.stripePlansSlugMap.pro.name} plans.`
          case 'starter': return `View ${this.formatMaxEventsTimeframe(this.stripePlansSlugMap.pro)} of history with the ${this.stripePlansSlugMap.pro.name} plan.`
          default: return null
        }
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
