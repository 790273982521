<template>
  <form @submit.prevent="onFilterChange">
    <div class="filters">
      <FormInput
        size="small"
        class="query"
        fieldName="query"
        :showLabel="false"
        :showError="false"
        @keydown="onQueryKeydown"
        :formName="filterFormName"
        :showValidationIcons="false"
      />
      <div class="button-group stacked-when-small">
        <OverflowChecklist
          :key="filterFieldName"
          :formName="filterFormName"
          :fieldName="filterFieldName"
          :filterOnDescriptions="false"
          v-for="filterFieldName in filterFieldNames"
        />
      </div>
      <PaginationTimerange
        v-if="timerangeFieldName"
        :formName="filterFormName"
        :fieldName="timerangeFieldName"
      />
    </div>
  </form>
  <div class="tag-container" v-if="numSelectedFilters !== 0">
    <span class="filtered-results-label">Filtered results ({{ formatNumber(paginationList.totalItems) }}):</span>
    <template
      :key="filterFieldName"
      v-for="filterFieldName in filterFieldNames"
    >
      <Tag
        :key="option.id"
        :clickable="true"
        v-for="option in selectedFilterOptions[filterFieldName]"
        @click="removeSelectedFilterOption(filterFieldName, option)"
      >
        <img :src="option.iconUrl" class="icon" v-if="option.iconUrl" />
        <component :is="option.icon" class="icon" v-else-if="option.icon" />
        {{ option.label }}
      </Tag>
    </template>
    <button class="link clear-filters" @click="resetFilterForm">Clear {{ numSelectedFilters }} filter{{ numSelectedFilters === 1 ? '' : 's' }}</button>
  </div>
</template>

<script>

  import { ref, toRefs } from 'vue'

  import useFilters from '@/composables/useFilters'
  import usePaginationFilters from '@/composables/usePaginationFilters'

  import Tag from '@/components/utils/Tag.vue'
  import FormInput from '@/components/forms/FormInput.vue'
  import OverflowChecklist from '@/components/forms/OverflowChecklist.vue'
  import PaginationTimerange from '@/components/utils/PaginationTimerange.vue'

  export default {
    components: {
      Tag,
      FormInput,
      OverflowChecklist,
      PaginationTimerange,
    },
    props: {
      filterFormName: {
        type: String,
        required: true,
        validator: (value) => {
          return (
            value === 'patchOverviewFiltersForm'
            || value === 'patchHistoryFiltersForm'
            || value === 'dispatchMonitorFiltersForm'
            || value === 'userContractHistoryFiltersForm'
            || value === 'userContractOverviewFiltersForm'
          )
        },
      },
      paginationListName: {
        type: String,
        required: true,
        validator: (value) => {
          return (
            value === 'userPatches'
            || value === 'userPatchEvents'
            || value === 'dispatchMonitorEvents'

            || value === 'userContracts'
            || value === 'userContractEvents'
          )
        },
      },
    },
    setup(props) {

      // data
      const timeoutId = ref(null)
      const timeoutDuration = 400

      // composables
      const { formatNumber } = useFilters()
      const paginationFilterComposables = usePaginationFilters(toRefs(props))

      return Object.assign({
        formatNumber,

        timeoutId,
        timeoutDuration,
      }, paginationFilterComposables)

    },
    watch: {
      'filterForm.fields.query.value': function formFieldsQueryValue(newValue, oldValue) {
        clearInterval(this.timeoutId)
        this.timeoutId = setTimeout(this.onFilterChange, this.timeoutDuration)
        this.$store.commit('pagination/SET_IS_LOADING', {
          listName: this.paginationListName,
          isLoading: true,
        })
      },
      selectedTimerangeOption(newValue, oldValue) {
        this.onFilterChange()
      },
      numSelectedFilters(newValue, oldValue) {
        if (newValue === 0) {
          this.onFilterChange()
          return
        }
        clearInterval(this.timeoutId)
        this.timeoutId = setTimeout(this.onFilterChange, this.timeoutDuration)
        this.$store.commit('pagination/SET_IS_LOADING', {
          listName: this.paginationListName,
          isLoading: true,
        })
      },
      'paginationList.isLoading': function paginationListIsLoading(newValue, oldValue) {
        if (!newValue) this.setAllFieldsDisabled(newValue)
      },
    },
    methods: {
      onQueryKeydown($event) {
        if ($event.code !== 'Enter') return
        if (!this.filterForm.fields.query.value) return
        $event.preventDefault()
        this.onFilterChange()
      },
      onFilterChange() {

        clearInterval(this.timeoutId)

        this.$store.commit('pagination/SET_IS_LOADING', {
          listName: this.paginationListName,
          isLoading: true,
        })

        this.applyFilters()

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .filters
    @apply mb-4
    @apply flex
    @apply gap-6
    @apply flex-col
    @apply items-start

    +breakpoint(lg)
      @apply flex-row

  .tag-container
    @apply mb-2

  .tags
    @apply mb-3
    @apply flex

  :deep(.input-container)
    @apply mb-0
    @apply w-auto

  :deep(.query)
    @apply flex-grow

    +breakpoint(xl)
      max-width: 32rem

  .filtered-results-label
    @apply mr-3

  .clear-filters
  .filtered-results-label
    @apply align-top
    @apply font-bold

    line-height: 1.75rem

</style>
