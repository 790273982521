<template>

  <OverflowMenu @on-open-change="onOpenChange">

    <template #trigger="slotProps">
      <button type="button" class="small filter" :class="{ 'is-open': slotProps.isOpen }" :disabled="field.disabled">
        {{ field.placeholder }}
        <span class="badge subtle" v-if="field.value.length !== 0">
          {{ field.value.length }}
        </span>
         <CaratIcon class="carat" />
      </button>
    </template>

    <div class="filter-container icon-overlay" v-if="isSearchable">
      <SearchIcon class="search-icon small" />
      <input
        type="search"
        ref="filterInput"
        v-model="filterQuery"
        placeholder="Search..."
        class="filter-input small"
        :disabled="field.disabled"
        @keydown="onFilterInputKeydown"
      />
    </div>

    <div ref="searchResults" class="search-results">
      <div
        :key="option.id"
        class="option-container"
        @mousedown="focusedOptionIndex = null"
        v-for="(option, index) in filteredOptions"
        @click="onOptionContainerClick(option.value)"
        :class="{ 'focused': focusedOptionIndex === index }"
      >
        <input
          @click.stop
          ref="formElement"
          :type="field.type"
          v-model="field.value"
          :value="option.value"
          :id="`${uuid}-${index}`"
          :required="field.required"
          @change="$emit('change', field)"
          :disabled="field.disabled || option.disabled"
          :class="{ 'error': field.isDirty && !!field.error }"
        />
        <label
          @click.stop
          class="label-text"
          :for="`${uuid}-${index}`"
          :class="{ 'disabled': field.disabled || option.disabled }"
        >
          <span class="option-title" v-if="option.label">
            {{ option.label }}
          </span>
          <span class="option-description" v-if="hasOptionsWithDescriptions && showDescriptions">
            <img :src="option.descriptionIconUrl" class="description-icon" v-if="option.descriptionIconUrl" />
            {{ option.description }}
          </span>
        </label>
      </div>
      <div class="no-filter-results" v-if="filteredOptions.length === 0">
        No results found.
      </div>
      <div class="reset-button-container" v-else>
        <button type="button" class="reset-button link" @click="resetField()" :disabled="field.value.length === 0">Clear</button>
      </div>
    </div>

  </OverflowMenu>

</template>

<script>

  import { toRefs } from 'vue'

  import useFormField from '@/composables/useFormField'

  import OverflowMenu from '@/components/utils/OverflowMenu.vue'

  import CaratIcon from '@/assets/icons/carat.svg'
  import SearchIcon from '@/assets/icons/search.svg'

  export default {
    emits: ['change'],
    components: {
      CaratIcon,
      SearchIcon,
      OverflowMenu,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      showDescriptions: {
        type: Boolean,
        default: true,
      },
      filterOnDescriptions: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      return useFormField(toRefs(props))
    },
    computed: {
      $filterInput() {
        return this.$refs.filterInput
      },
      $searchResults() {
        return this.$refs.searchResults
      },
      isSearchable() {
        return this.field.options.length > 5
      },
      filteredOptions() {

        if (!this.isSearchable || !this.filterQuery) {
          return this.field.options
        }

        const regex = new RegExp(`.*${this.filterQuery}.*`, 'i')

        return this.field.options.filter((option) => {

          const labelMatch = regex.test(option.label)

          // prioritize a label match over a description match
          if (labelMatch) return labelMatch

          if (this.filterOnDescriptions && this.showDescriptions) {
            return regex.test(option.description)
          }

          return false

        })

      },
    },
    data() {
      return {
        filterQuery: '',
        focusedOptionIndex: null,
      }
    },
    methods: {
      resetField() {
        this.$store.commit('forms/RESET_FORM_FIELD', { formName: this.formName, fieldName: this.fieldName })
      },
      // @NOTE: Element.scrollIntoView() doesn't seem to work super well... it
      //  takes the whole page container (behind the search) into account, which
      //  scrolls the page weirdly when navigating through search results with
      //  the keyboard... so let's just write our own logic for that
      scrollOptionIntoView(index) {

        const $target = this.$searchResults.querySelector(`.option-container:nth-of-type(${index + 1})`)

        const targetTop = $target.offsetTop
        const targetBottom = targetTop + $target.offsetHeight

        const lowerBound = this.$searchResults.scrollTop
        const upperBound = lowerBound + this.$searchResults.offsetHeight

        if (targetTop < lowerBound) this.$searchResults.scrollTop = targetTop
        if (targetBottom > upperBound) this.$searchResults.scrollTop += targetBottom - upperBound

      },
      onFilterInputKeydown($event) {
        switch ($event.code) {
          case 'ArrowUp':
            if (this.focusedOptionIndex === null) this.focusedOptionIndex = this.filteredOptions.length
            this.focusedOptionIndex = Math.max(0, this.focusedOptionIndex - 1)
            this.scrollOptionIntoView(this.focusedOptionIndex)
            $event.preventDefault()
            break

          case 'ArrowDown':
            if (this.focusedOptionIndex === null) this.focusedOptionIndex = -1
            this.focusedOptionIndex = Math.min(this.filteredOptions.length - 1, this.focusedOptionIndex + 1)
            this.scrollOptionIntoView(this.focusedOptionIndex)
            $event.preventDefault()
            break

          case 'Space':
            if (this.filterQuery) return
          // fall through

          case 'Enter': {
            if (this.focusedOptionIndex === null) return
            this.onOptionContainerClick(this.filteredOptions[this.focusedOptionIndex].value)
            $event.preventDefault()
            break
          }

          default:
        }
      },
      onOptionContainerClick(optionValue) {

        if (!this.field.options) return

        let newValue = Array.from(this.field.value)

        if (this.field.value.includes(optionValue)) {
          newValue = newValue.filter((value) => {
            return value !== optionValue
          })

        } else {
          newValue.push(optionValue)
        }

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue,
        })

        this.$emit('change', this.field)

      },
      onOpenChange(isOpen) {

        if (!isOpen) return

        // if searchable, auto-focus the filter input
        if (this.isSearchable) {

          this.filterQuery = ''
          this.focusedOptionIndex = null

          // @NOTE: this must come in a $nextTick() since the filterInput $ref
          //  won't exist until the menu is actually shown due to the v-if
          this.$nextTick(() => {
            this.$filterInput.focus()
            this.$searchResults.scrollTop = 0
          })

        }

      },
    },
  }

</script>

<style lang="stylus" scoped>

  input[type="checkbox"]
    @apply mt-1

  .carat
    @apply w-3
    @apply h-3
    @apply ml-2

  .filter-container
    @apply z-30
    @apply top-0
    @apply left-0
    @apply h-auto
    @apply sticky

    .filter-input.small
      @apply pl-7
      @apply pr-4
      @apply border-transparent

    &+.search-results
      @apply border-t
      @apply border-gray-400

  .search-results
    @apply relative
    @apply overflow-y-auto

    max-height: calc((2rem * 7.5) + 2.25rem)

  .option-container
    @apply items-start

    &:first-child
      @apply rounded-none

  .label-text
    @apply ml-2
    @apply mb-0
    @apply text-base
    @apply font-normal
    @apply text-gray-800
    @apply cursor-pointer

    @apply flex
    @apply flex-col

    &.disabled
      @apply opacity-50
      @apply cursor-not-allowed

  .option-description
    @apply text-xs
    @apply leading-5
    @apply text-gray-600

    @apply flex
    @apply items-start

    .description-icon
      @apply w-3
      @apply h-3
      @apply mt-1
      @apply mr-1

      min-width: theme('width.3')

  .reset-button-container
    @apply py-2
    @apply px-4
    @apply sticky
    @apply bg-white
    @apply bottom-0
    @apply border-t
    @apply border-gray-400

    @apply flex
    @apply justify-center

    .reset-button
      @apply w-auto

      padding: 1px

      &:not([disabled])
        @apply text-primary-500

      &:hover:not([disabled])
        @apply underline
        @apply bg-transparent
        @apply text-primary-500

</style>
